<template>
  <div class="services-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
    <ServiceComponent
      v-for="(service, index) in services"
      :key="index"
      :title="service.title"
      :name="service.name"
      :description="service.description"
      :disabled="service.disabled"
      :icon-name="service.iconName"
      :link="service.link"
    />
  </div>
</template>

<script>
import ServiceComponent from "@/components/navigation/Service.vue";

export default {
  name: "AktioServices",
  components: {
    ServiceComponent,
  },
  data() {
    return {
      services: [
        {
          title: "aktio indsigt",
          name: "indsigt",
          description: "Aktio Indsigt hjælper med arbejdet til aktindsigter og understøtter lovgivning, sagstyper, ekstrahering, afgørelsesbreve, journalisering, samarbejde om sager og integration til fagsystemer.",
          disabled: false,
          iconName: "fa-solid fa-file-lines",
          link: "/",
        },
        {
          title: "aktio indsigt light",
          name: "indsigt light",
          description: "En light version af Aktio Indsigt der understøtter ekstrahering, søgning og gennemgang af enkelte dokumenter",
          disabled: false,
          iconName: "fa-regular fa-file-lines",
          link: "/light/tasks",
        },
        {
          title: "aktio dokumentklip",
          name: "dokumentklip",
          description: "Klippe Værktøj til at splitte dokumenter i flere dele eller samle dokumenter til en enkelt dokumentsamling.",
          disabled: true,
          iconName: "fa-scissors",
          link: "/",
        },
        {
          title: "aktio gpt",
          name: "gpt",
          description: "Værktøj til at lave resumé, søge og forklare alt om et uploadet dokument",
          disabled: true,
          iconName: "fa-laptop-code",
          link: "/",
        },
        {
          title: "aktio ocr",
          name: "ocr",
          description: "Værktøj til at OCR behandle og optimere PDF filer",
          disabled: true,
          iconName: "fa-laptop-file",
          link: "/",
        },
        {
          title: "aktio gdpr",
          name: "gdpr",
          description: "Upload filer af alle typer og se hvor der er GDPR sensitivt data.",
          disabled: true,
          iconName: "fa-magnifying-glass-chart",
          link: "/",
        },
        {
          title: "aktio konverter",
          name: "konverter",
          description: "Konverter dine filer til PDF. Dette inkludere Word, Excel, PowerPoint, PNG, Mail filer m. vedhæftninger, PNG og mange flere",
          disabled: true,
          iconName: "fa-file-export",
          link: "/",
        },
        {
          title: "aktio editor",
          name: "editor",
          description: "Opret nye skabeloner til afgørelsesbreve baseret på lovgivninger til aktindsigter.",
          disabled: true,
          iconName: "fa-file-signature",
          link: "/",
        },
        {
          title: "aktio tidsmaskine",
          name: "tidsmaskine",
          description: "En tidsmaskine til at rette op på fortiden eller se ud i fremtiden.",
          disabled: true,
          iconName: "fa-hourglass-start",
          link: "/",
        },
      ],
    };
  },
};
</script>


<style>
</style>
